<template>
  <div class="place-items-center p-email-input">
    <div class="p-email-input__wrapper">
      <p-text-input
        v-model="emailAutoComplete"
        :name="name"
        type="text"
        :label="label"
        show-clear
        @on-input="handleInput"
      >
        <template #append>
          <p-icon
            v-if="emailAutoComplete"
            icon="circle-close"
            size="xmd"
            color="foreground-tertiary"
            class="p-search-input--close"
            @on-click="itemClickHandler({ value: '' })"
          />
        </template>
      </p-text-input>
      <div v-if="showSuggestions" class="p-menu">
        <div
          v-for="email in filteredEmails"
          :key="email"
          class="p-menu__item"
          @click="itemClickHandler({ value: email })"
        >
          <div class="p-email-input__item">
            <span class="p-email-input__value">{{ emailAutoComplete }}</span
            ><span class="t-foreground-primary">{{ email.replace(emailAutoComplete, '') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const providers = ['gmail.com', 'hotmail.com', 'icloud.com', 'outlook.com', 'yahoo.com']
const showSuggestions = ref(false)

defineProps({
  name: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    required: true,
  },
})

const emailAutoComplete = defineModel<string>('modelValue', { default: '' })

const filteredEmails = computed<string[]>(() => {
  if (!emailAutoComplete.value.includes('@')) {
    return providers.map((provider) => `${emailAutoComplete.value}@${provider}`)
  }
  return []
})

const handleInput = () => {
  showSuggestions.value =
    emailAutoComplete.value.length >= 3 && !emailAutoComplete.value.includes('@')
}

const itemClickHandler = (item: { value: string }) => {
  emailAutoComplete.value = item.value
  showSuggestions.value = false
}
</script>
